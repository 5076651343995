//font base is 16px transform into em use em() function send px and pt as parameter
h1,
.big-title {
    color: black;
    font-size: em(36);
    @extend %text-light;

    .leading {
        font-size: em(64);
    }

    .tracking {
        font-size: em(63);
    }

    @include respond-to('md') {
        font-size: em(60);
    }

    &.title {
        line-height: 1.2;
        font-size: 3em;
    }
}

h2,
.header {
    color: black;
    font-size: em(24);
    line-height: 1.35;
    margin: 10px 0;
    @extend %text-light;

    .leading,
    .tracking {
        font-size: em(44);
    }

    @include respond-to('md') {
        font-size: em(36);
    }
}

//h2

h3 {
    color: black;
    font-size: 21px;
    line-height: 1.57;
    @extend %text-regular;

    &.leading,
    &.tracking {
        font-size: em(24);
    }

    &.subtitle {
        line-height: 1.5;
    }

    .c-soon {
        font-size: em(16);
    }
}

h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;

    .leading,
    .tracking {
        font-size: em(23);
    }
}

h6 {
    color: black;
    font-size: 30px;
    line-height: 1.2;
    @extend %text-light;

    .leading,
    .tracking {
        font-size: 36;
    }

    &.header {
        font-size: 30px;
    }
}

//h6

h7 {
    color: black;
    font-size: em(24);

    @extend %text-light;

    .leading,
    .tracking {
        font-size: em(32);
    }
}

.lead,
.subhead {
    @extend %text-light;
    font-size: em(21);
    line-height: 33px;
    max-width: 902px;
    margin: 35px auto;
}

.subhead {
    @extend .lead;
    font-size: 1em;
    line-height: 1.44;

    @include respond-to("md") {
        font-size: 21px;
    }
}

.eye-brow {
    font-size: em(18);
    color: $color-dark-purple;
}

.trade-mark {
    font-size: em(10);
    margin-right: 5px;
}

p {
    &.big-title {
        @extend h1;
    }

    &.check {
        text-indent: -0.75em;
        padding-left: 1.5em;

        i {
            padding-right: 20px;
            color: $color-aquamarine;
        }
    }

    @include respond-to("xxs") {
        font-size: 14px;
    }
}

//p

a {
    color: $color-purple;
    text-decoration: none;

    @include respond-to("xxs") {
        font-size: 14px;
    }

    &.bottom-section-link {
        @extend .lead;
        margin: initial;
        max-width: 100%;
        border-bottom: 1px solid $color-dark-purple;

        &:hover {
            border-bottom: 1px solid darken($color-dark-purple, 20%);
        }
    }

    &:hover {
        outline: none;
        text-decoration: none;
        color: darken($color-dark-purple, 20%);
    }

    &.link-underline {
        text-decoration: underline;
    }

    &.text- {
        &blue {
            color: $color-dark-purple;

            &:focus,
            &:hover {
                color: $color-dark-purple;
            }
        }

        &blue-dark {
            color: $color-text-dark;

            &:focus,
            &:hover {
                color: $color-text-dark;
            }
        }

        &white {
            color: white;

            &:focus,
            &:hover {
                color: white;
            }
        }

        &dark {
            color: $color-alto-dark;

            &:focus,
            &:hover {
                color: $color-alto-dark;
            }
        }

        &green {
            color: $color-green;

            &:focus,
            &:hover {
                color: $color-green;
            }
        }

        &yellow {
            color: $color-yellow;

            &:focus,
            &:hover {
                color: $color-yellow;
            }
        }

        &orange {
            color: $color-orange;

            &:focus,
            &:hover {
                color: $color-orange;
            }
        }

        &turquoise {
            color: $color-turquoise;

            &:focus,
            &:hover {
                color: $color-turquoise;
            }
        }

        &aquamarine {
            color: $color-aquamarine;

            &:focus,
            &:hover {
                color: $color-aquamarine;
            }
        }
    }
}

.text- {
    &left {
        text-align: left;
    }

    &right {
        text-align: right;
    }

    &center {
        text-align: center;
    }

    &small {
        font-size: em(12) !important;
        line-height: 1.44 !important;
    }

    &alpha {
        color: rgba(0, 0, 0, 0.7) !important;

        &-white {
            color: rgba(255, 255, 255, 0.7) !important;
        }
    }

    //alpha

    &light {
        @extend %text-light;
    }

    &blue {
        color: $color-dark-purple;
    }

    &purple {
        color: $color-purple;
    }

    &dark-purple{
        color: $color-text-dark;
    }

    &white {
        color: white !important;
    }

    &black {
        color: black !important;
    }

    &green {
        color: $color-green;
    }

    &orange {
        color: $color-orange;
    }

    &yellow {
        color: $color-yellow;
    }

    &aquamarine {
        color: $color-aquamarine;
    }

    &fuchsia {
        color: $color-fuchsia;
    }
}

//text

.new-design {
    h1 {
        font-weight: 800;
        font-size: 1.5rem;
        line-height: 1.5rem;
        letter-spacing: -0.05em;

        @include respond-to("md") {
            font-size: 2.125em;
            line-height: 2.125em;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 1.125em;
        line-height: 1.25rem;
        letter-spacing: -0.05em;

        @include respond-to("md") {
            font-size: 1.875rem;
            line-height: 2rem;
        }

        .p-t-sm {
            color: black;
            font-size: 1.5em;
            line-height: 1.35;
            margin: 10px 0;

            @media (min-width: 992px) {
                font-size: 2.25em;
            }
        }
    }

    h3 {
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
        letter-spacing: -0.05em;

        @include respond-to("sm") {
            font-size: 50px;
            line-height: 58px;
            letter-spacing: 0;
        }
    }

    h4 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;

        @include respond-to("sm") {
            font-size: 30px;
            line-height: 40px;
        }

        @include respond-to("md") {
            font-size: 35px;
            line-height: 45px;
        }

        @include respond-to("lg") {
            font-size: 42px;
            line-height: 52px;
        }
    }

    h5 {
        color: $color-alto-dark;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.035em;

        @include respond-to("lg") {
            font-size: 36px;
            line-height: 42px;
            letter-spacing: 0;
        }
    }

    h6 {
        color: $color-alto-dark;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.035em;
        font-weight: bold;

        @include respond-to("md") {
            font-size: 24px;
            line-height: 32px;
        }
    }

    p {
        color: $color-alto-dark;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @include respond-to('md') {
            line-height: 28px;
        }

        @include respond-to('lg') {
            font-size: 18px;
            line-height: 32px;
        }
    }

    a.arrow-link {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.035em;
        width: fit-content;

        @include respond-to('md') {
            font-size: 20px;
        }

        &:focus,
        &:hover {
            text-decoration: none;
        }

        &::after {
            content: " ";
            color: $color-text-gray;
            width: 26px;
            height: 26px;
            display: inline-block;
            vertical-align: bottom;
            margin-left: 10px;
        }

        &.white {
            &::after {
                background: url('../images/new-icons/arrow-right-bg-white.svg') no-repeat;
            }

            &:hover {
                &::after {
                    background: url('../images/new-icons/arrow-right-bg-white-hover.svg') no-repeat;
                }
            }
        }

        &.blue {
            &::after {
                background: url('../images/new-icons/arrow-right-bg-blue.svg') no-repeat;
            }

            &:hover {
                color: $color-cta-primary-roll;

                &::after {
                    background: url('../images/new-icons/arrow-right-blue-bg-hover.svg') no-repeat;
                }
            }

            &.full {
                &::after {
                    background: url('../images/new-icons/arrow-right-bg-primary-blue.svg') no-repeat;
                }

                &:hover {
                    color: $color-dark-purple;
                }
            }
        }

        &.purple {
            color: $color-purple;

            &::after {
                background: url('../images/new-icons/arrow-right-bg-purple.svg') no-repeat;
            }

            &:hover {
                color: $color-cta-primary-roll;

                &::after {
                    background: url('../images/new-icons/arrow-right-purple-bg-hover.svg') no-repeat;
                }
            }

            &.full {
                &::after {
                    background: url('../images/new-icons/arrow-right-bg-primary-blue.svg') no-repeat;
                }

                &:hover {
                    color: $color-purple;
                }
            }
        }
    }

    .leadIn {
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        color: lighten($color-alto-dark, 20%);

        @include respond-to('md') {
            font-size: 24px;
            line-height: 32px;
            color: lighten($color-alto-dark, 50%);
        }
    }
}

.sub-headline {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    display: inline-block;
}

%h1{
    font-size: 40px;
    line-height: 42px;
    @media (max-width: 768px) {
            font-size: 28px;
            line-height: 34px;
      }
}
%h2{
 font-size: 36px;
    line-height: 36px;
    @media (max-width: 768px) {
            font-size: 26px;
            line-height: 30px;
      }
}
%h3{
    font-size: 32px;
       line-height: 32px;
       @media (max-width: 768px) {
               font-size: 24px;
               line-height: 28px;
         }
   }
%h4{
    font-size: 28px;
       line-height: 28px;
       @media (max-width: 768px) {
               font-size: 22px;
               line-height: 22px;
         }
   }
%h5{
    font-size: 24px;
       line-height: 28px;
       @media (max-width: 768px) {
               font-size: 20px;
         }
   }

%p{
    font-size: 20px;
    line-height: 24px;
    @media (max-width: 768px) {
        font-size: 18px;
  }
}

//TBD New icon arrows with Google fonts
// a.new-arrow {
//     .material-symbols-outlined {
//         vertical-align: middle;
//         font-size: 26px;
//         margin-left: 10px;
//         &.fill {
//             font-variation-settings: 
//                 'FILL' 1, 
//                 'wght' 300, 
//                 'GRAD' -25, 
//                 'opsz' 20;
//         }

//         &.purple {
//             color: $color-purple;
//             background-color: #4F2E841A;
//             border-radius: 50%;
//         }
//     }

//     &:hover {
//         &.purple {
//             color: $color-purple;
//         }

//         .material-symbols-outlined.purple {
//             background-color: $color-purple;
//             color: white;
//         }
//     }
// }