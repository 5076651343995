section.new-design {
	@extend %transition;
	position: relative;

	&:after {
		@extend .clearfix;
	}

	ul.navbar-nav {
		padding: 0;

		li {
			margin: 0;
		}

		>li.dropdown.open .dropdown-menu {
			@include respond-to("md") {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				right: 0;
				margin: auto;
				width: 90%;
				max-width: 1280px;
				font-size: 16px;
			}
		}
		.dropdown-menu{
			margin-top: 0;
			border-radius: 0 0 0.375rem 0.375rem;
			ul{
				list-style: none;
				margin: 0;
				padding-bottom: 0;
				li{
					min-width: max-content;
					width: 100%;
					padding: 3px 0;
					a{
						color: $color-purple;
					}
				}
			}
		}
		.custom-dd-divider{
			display: none;
			padding: 0!important;
			@include respond-to("lg"){
				display: block;
				height: 1px;
				position: relative;
				width: 100%!important;
				margin: 7px 0;
				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 90%;
					background: gray;
					height: 100%;
				}
			}
			

		}
	}

	.navbar.navbar-default {
		background: $primary-color;
		border: none;
		z-index: 4;
		margin-bottom: 0;
		border-radius: 0;
		padding-bottom: 0;
		box-shadow: none;
		width: 100%;
		.topnav{
			height: 50px;
			max-width: 1280px;
			.nav-right{
				height: 100%;
				margin-right: 15px;
			}
			#searchButton{
				&.opened{
					border-bottom: 4px solid $color-yellow;
				}
			}
		}
		a.navbar-brand {
			//delete old design
			width: unset;
			height: auto;
			padding: 0;

			img {
				width: 100px;

				@include respond-to("lg") {
					width: 173px;
					margin-right: 20px;
				}
			}
		}
		.avatar-container {
			position: relative;
			display: inline-block;

			@include respond-to ("lg-down"){
				color: white;
				font-size: 10px;
				display: flex;
				flex-direction: column;
				padding-top: 5px;
			}

			img {
				margin-left: 5px;
			}

			.avatar-hot {
				height: 24px;
				width: 24px;
			}
			span.badge-notification{
				width: 19px;
				height: 18px;
				background: #da5666;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				color: white;
				z-index: -1;
				border-radius: 3px;
				position: absolute;
				top: 6px;
				z-index: 1;
				right: 10px;
				&:after{
					display: none!important;
				}
			}

			.avatar-cold {
				height: 24px;
				width: 23px;
				vertical-align: text-top;
			}

			.fa.fa-circle {
				position: absolute;
				top: 28px;
				left: 25px;
				font-size: em(12);
			}
		}

		.container-fluid {
			max-width: 1280px;
			display: flex;
			align-items: center;
			width: 95%;
			height: 56px;

			@include respond-to("lg") {
				height: 84px;
			}
			.divider {
				width: 1px;
				height: 43px;
				background: #fff;
				opacity: 0.3;
				margin: 0 20px;
			}
		}

		.navbar-collapse {
			border: none;

			@include respond-to('lg-down') {
				border-top: 1px solid transparent;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);

				&.collapse {
					display: none ;
					&.collapsing, &.show{
						display:flex !important;
						flex-direction:column;
						transition: height .35s ease;
					}
				}

				&.collapse.in {
					display: block !important;
				}
			}
		}

		.navbar-nav {
			>li {
				>a {
					color: $color-white;
					font-weight: 700;
					text-transform: capitalize;
					line-height: 55px;
					font-size: 16px;
					height: 69px;
					border-bottom: 4px solid transparent;
					padding: 0;
					margin: 15px 20px;
					margin-bottom: 0;
					@extend %transition;

					&:hover,
					&:focus {
						border-bottom: 4px solid $color-yellow;
						outline: none;
					}
					&.show{
						border-bottom: 4px solid $color-yellow;
						outline: none;
					}
				}

				&.open {
					>a {
						background: none;
						@extend %transition;
						border-bottom: 4px solid $color-yellow;

						&:hover,
						&:focus {
							color: $color-white;
						}
					}
				}
			}
		}

		.navbar-toggler {
			border: none;
			float: left;
			margin: 0;
			margin-left: 20px;
			padding-right: 0;
			display: none;

			&:hover,
			&:focus {
				background: none;
			}

			&:focus {
				outline: -webkit-focus-ring-color auto 1px;
			}

			&.collapsed {
				// display: block;
    			width: 30px;
    			height: 32px;
				display: flex!important;
				flex-direction: column;
				justify-content: center;
				gap: 4px;
				.icon-bar{
					width: 100%;
					max-width: 100%;
					height: 2px;
					position: relative;
					display: block;
    				background-color: #fff;
				}
				&:after {
					content: none;
				}
			}

			~.icon-close {
				width: 40px;
				height: 40px;
				background: url('../images/new-icons/close-menu-mobile.svg') no-repeat;
				margin-top: 3px;
				margin-left: 20px;
				margin-right: -10px;
			}

			.icon-bar {
				width: 20px;
				background-color: $color-white;
			}

			&.collapsed {
				display: block;

				~.icon-close {
					display: none;
				}
			}
		}
		

		.nav-center {
			@extend .flex-center;
			padding-left: 0;
			@include respond-to('lg-down') {
				position: fixed;
				top: 64px;
				max-height: calc(100vh - 64px);
				padding-bottom: 100px;
				height: 100%;
				left: 0;
				width: 100%;
				background: white;
				overflow: auto;

				.card-container{
					width: fit-content;
					margin: auto;
					padding: 20px;
					border-top: 1px solid #e7e4df;
					border-bottom: 0px !important;
				}

				.mobile-language-switcher{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 8px;
					margin-bottom: 24px;
					a .name:after{
						display: none;
					}
					.language-switch{
						display: block;
						max-width: 96px;
						margin: auto;
						gap: 20px;
						width: 100%;
					}  
				}
				
				&.animate {
					animation-duration: 0.8s;
					-webkit-animation-duration: 0.8s;
					animation-fill-mode: both;
					-webkit-animation-fill-mode: both;
				}
				
				  
				@keyframes slideIn {
					0% {
					  transform: translateY(1rem);
					  opacity: 0;
					}
					100% {
					  transform: translateY(0rem);
					  opacity: 1;
					}
					0% {
					  transform: translateY(1rem);
					  opacity: 0;
					}
				}
				
				&.slideIn {
					-webkit-animation-name: slideIn;
					animation-name: slideIn;
				}

				a{	
					border-bottom: 1px solid #e7e4df!important;

					span{
						color: $color-purple;
						&:after {
							display: inline-block;
    						content: " ";
    						background: url(../images/new-icons/arrow-down-purple.svg) no-repeat !important;
							margin-left: 6px;
							width: 10px;
							height: 8px;
							border: none;
						}
					}
					&.show{
						span:last-child{
							&:after{
								content: " ";
    							background: url(../images/new-icons/arrow-up-purple.svg) no-repeat !important;
								margin-left: 5px;
								width: 11px;
								height: 9px;
							}
						}
						
					}
					&:after {
						display: none;
					}
					
				}

				.navbar-nav{
					margin: 0
				}
				.dropdown-menu{
					background-color: #F5F4F1;
					border: none;
					margin: 0;
					padding: 0;
					ul{
						padding-bottom: 0;
						li{
							width: 100%;
							border-bottom: 1px solid #e7e4df !important;
							min-width: 100%;
							padding: 5px 0;
						}
					}
					a{
						line-height: 24px;
						border-bottom: none!important;
						display: block;
						padding: 12px 0;
					}
				}
				
			}
			>div.dropdown {
				>a {
					display: block;
					color: #fff;
					font-weight: 700;
					text-transform: capitalize;
					line-height: 55px;
					font-size: 16px;
					height: 69px;
					border-bottom: 4px solid transparent;
					padding: 0;
					margin: 15px 20px;
					margin-bottom: 0;
					margin-right: 0px;
					@extend %transition;
					.navbar-text {
						font-weight: 600;
						color: $color-white;
						margin: 0;
						display: flex;
						align-items: center;
	
						.name {
							min-width: 58px;
							@extend %ellipsis;
							color: $color-white;
						}
					}
					
					&:hover {
						outline: none;
						border-bottom: 4px solid $color-yellow;
					}
					&.language-switch{
						background: $color-gray;
						border:none;
						&.active{
							background: $color-yellow;
						}
						&:hover{
							border: none;
							box-shadow: 0px 0px 1px 1px grey;
						}
						.navbar-text{
							color: $color-purple;
							font-size: 14px;
							padding: 2px 5px;
							text-align: center;
						}
					}
				}

				.open {
					border-bottom: 4px solid $color-yellow;
				}
			}

			.login-curtain {
				display: none;
				position: absolute;
				width: 420px;
				// padding: 25px 20px;
				background-color: white;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
				padding: 0;
				z-index: 1021;
				
				&.pointed{
					filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
					box-shadow: none;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
					background: transparent;
					padding: 0;
					border: none;
					.login-curtain__container{
						padding-bottom: 40px;
						.singin-cta__container{
							// padding-bottom: 20px;
							border-bottom: 1px solid gray;
							.not-you{
								font-size: 14px;
								font-weight: 400;
							}
							p{
								padding-bottom: 20px;
							}
						}
					}
				}
				.login-curtain__container{
					padding: 25px 20px;
					background: white;
					clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
					
				}

				a.btn {
					display: block;
					width: 100%;
					max-width: 195px;
					margin: auto;
					padding: 8px;
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 10px;
				}

				p {
					font-size: 11px;
					font-weight: 600;
					color: $color-dark-purple;
					margin-bottom: 0;
					padding-bottom: 0;
					line-height: 1;
					text-align: center;
				}
				.extended-sign-in-menu{
					padding-top: 0!important;
					border-top: 0!important;
				}
				.extended-sign-in-menu{
					margin-top: 10px;
					border-top: 1px solid gray;
					padding: 0;
					padding-top: 15px;
					&>div>div{
						
						&:nth-child(1){
							border-right: 1px solid gray;
						}
						ul{
							list-style: none;
							padding-bottom: 0;
							margin: 0;
							li{
								a{
									color: $color-purple;
									font-size: 14px;
									
									&.profile-menu-accordion {
										&::after{
											float: none;
											margin-left: 5px;
										}
										+ ul {
											a{
												&::before {
													content: '>\00a0';
												}
											}
										}
									}
								}
							}
						}
					}
					.important-messages_lists{
						.signin-title{
							margin-top: 0;
							padding-top: 0;
							span{
								cursor: pointer;
							}
						}
						
					}
					.account-links{
						.incorrect-user{
							text-align: center;
							margin-top: 20px;
							a.link-underline{
								font-weight: 700;
							}
						}
						li{
							line-height: 1;
						}
					}
					.signin-title{
						text-align: center;
						font-weight: bold;
						color: #7e7e7e;
						display: block;
						font-size: 1rem;
					}
					.important-messages{
						a{
							display: flex;
							align-items:center; 
						}
						.badge-notification{
							width: 19px;
							height: 18px;
							background: #da5666;
							display: flex;
							justify-content: center;
							align-items: center;
							color: white;
							z-index: -1;
							border-radius: 3px;
							margin-left: 5px;
						}
					}
					.important-message-container{
						display: none;
						div{
							border-right: none!important;
						}
						.important-messages_lists{
							.signin-title{
								button{
									cursor: pointer;
									background: transparent;
									border: none;
									color: #7e7e7e;
									font-weight: 600;
								}
							}
						}
						
						.signin-title{
							width: 100%;
							text-align: left;
							display: flex;
							justify-content: space-between;
							border-bottom: 1px solid gray;
							padding-bottom: 10px;
						}
						.message-list{
							p{
								text-align: left;
							}
							
							.message-title{
								color: $color-purple;
								font-weight: bold;
								display: flex;
								align-items: baseline;
								justify-content: space-between;
								font-size: 14px;
								line-height: 1.5;
							}
							.message-description{
								display: flex;
								align-items: baseline;
								font-size: 14px;
								p{
									font-size: 14px;
								}
							}
							.important-message__see-more{
								color: $color-purple;
								font-size: 11px;
								padding-left: 5px;
								text-decoration: underline;
								cursor: pointer;
							}
							.important-message__see-less{
								display: none;
								margin-left: 10px;
								cursor: pointer;
								background: transparent;
								color: $color-purple;
								border: none;
								font-weight: 600;
							}
						}
						.message-expanded{
							
							.message-description{
								p{	
									text-overflow: unset;
									max-width: 100%;
									white-space: unset;
									overflow: visible;
									line-height: 1.5;
								}
							}
						}
						.message-description{
							p{	
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								max-width: 100%;
							}
						}
					}
				}
			}
		}

		.nav-right {
			@extend .flex-center;
			justify-content: flex-end;
			flex: 1;
			.name{
				&::after{
					display: none!important;
					content: '';
				}
			}
			.avatar-container_mobile{
				span.badge-notification{
					position: absolute;
					top: -8px;
					z-index: 1;
					right: -11px!important;
				}
			}
			

			.nav-right-desktop {
				position: relative;
				@extend .flex-center;
				// margin-right: -10px;
				justify-content: start;

				>ul,
				>li {
					padding: 0;
					margin: 0;
					list-style: none;
				}

				>.dropdown {
					>a {
						display: block;
						// height: 84px;
						// line-height: 84px;
						border-bottom: 4px solid transparent;
						@extend %transition;

						
						&:hover {
							outline: none;
							border-bottom: 4px solid $color-yellow;
						}
						&.language-switch{
							background: $color-gray;
							border:none;
							&.active{
								background: $color-yellow;
							}
							&:hover{
								border: none;
								box-shadow: 0px 0px 1px 1px grey;
							}
							.navbar-text{
								color: $color-purple;
								font-size: 14px;
								padding: 2px 5px;
								text-align: center;
							}
						}
					}

					.open {
						border-bottom: 4px solid $color-yellow;
					}
				}

				.language-switch__selector{
					.dropdown-menu.show{
						min-width: fit-content;
						font-size: 14px;
						li{
							margin: 0;
						}
					}
				}

				.navbar-text {
					font-weight: 600;
					color: $color-white;
					margin: 0;
					display: flex;
					align-items: center;

					.name {
						min-width: 58px;
						@extend %ellipsis;
					}
				}

				&.warm-hot {
					.dropdown {
						.up-down-arrow {
							width: 10px;
							height: 8px;
							@extend %arrow-down-custom;
						}

						&.open {
							.up-down-arrow {
								@extend %arrow-up-custom;
							}
						}
					}
				}

				.divider {
					width: 1px;
					height: 43px;
					background: $color-white;
					opacity: 0.3;
					margin: 0 20px;
				}

				.scWebEditInput {
					width: unset;
				}

				
			}

			.nav-right-mobile {
				@extend .flex-center;
				button.profile-toggle{
					background: transparent;
					border: none;
				}
				button{
					border: none;
					&:focus{
						border: none;
						outline: none;
						box-shadow: none;
					}
				}
				.close-icon {
					vertical-align: middle;
					width: 10px;
					color: transparent;
					font-size: 20px;
					margin-left: 0;
					line-height: 1;
				}

				.fa.fa-circle {
					top: 0 !important;
				}

				&.warm-hot {
					.up-down-arrow {
						width: 10px;
						height: 6px;
						@extend %arrow-down-custom;
					}

					.close-icon {
						display: none;
					}

					.collapsed {
						.up-down-arrow {
							display: none;
						}

						.close-icon {
							color: $color-primary-beige;
							display: inline-block;
						}
					}
				}

				&.cold {
					.avatar-cold {
						margin-right: 4px;
					}

					.avatar-cold-circle {
						display: none;
					}

					.collapsed {
						.avatar-cold {
							display: none;
						}

						.avatar-cold-circle {
							display: inline-block;
						}

						.close-icon {
							margin-top: 0px;
							margin-left: 1px;
							color: $color-primary-beige;
							display: inline-block;
						}
					}
				}
			}
		}

		.dropdown {
			// position: static !important;
			width: 100%;
			padding: 0;

			@include respond-to("lg") {
				width: auto;
			}

			>a.dropdown-toggle {
				span:last-child{
					&:after {
						@extend %arrow-down-custom;
						margin-left: 6px;
						width: 10px;
						height: 8px;
						border: none;
					}
				}
				&.show{
					span:last-child{
						&:after{
							@extend %arrow-up-custom;
							margin-left: 5px;
							width: 11px;
							height: 9px;
						}
					}
					
				}
				&:after {
					display: none;
				}
			}

			&.open{
				>a.dropdown-toggle {
					&:after {
						@extend %arrow-up-custom;
						margin-left: 5px;
						width: 11px;
						height: 9px;
					}
				}
			}

			&.no-icon {
				>a.dropdown-toggle {
					&:after {
						content: " ";
					}
				}
			}
		}
	}

	.card-container {
		@extend .flex-center;

		.header-card {
			max-width: 121px;
			height: auto;

			@include respond-to("lg") {
				max-width: 70px;
			}
		}

		div.card-text {
			display: flex;
			flex-direction: column;
			font-weight: 600;
			margin-left: 20px;
			height: 76px;

			@include respond-to("lg") {
				width: 140px;
				margin-left: 7px;
				margin-right: -10px;
				height: 45px;
			}

			.product-name {
				color: $color-alto-dark;
				font-size: 14px;
				line-height: 14px;
				padding-bottom: 2px;
				width: 70%;

				@include respond-to("lg") {
					color: $color-white;
					font-size: 10px;
					width: 100%;
				}
			}

			.product-action {
				color: white;
				background-color: $color-turquoise;
				text-transform: uppercase;
				font-size: 16px;
				letter-spacing: 0.0556em;
				max-width: 142px;
				padding: 4px 10px;
				border-radius: 4px;
				margin-top: auto;
				display: flex;
				justify-content: center; 
				align-items: center;

				@include respond-to("lg") {
					padding: 4px 14px;
					font-size: 12px;
					line-height: 14px;
					max-width: 120px;
				}
			}
		}

		i {
			&:after {
				content: " ";
				vertical-align: middle;
				display: inline-block;
				background: url(../images/new-icons/chevron-right-link-blue.svg) no-repeat;
				width: 26px;
				height: 26px;

				@include respond-to("lg") {
					background: url(../images/new-icons/chevron-right-link-white.svg) no-repeat;
				}
			}
		}
	}

	.alert-text {
		font-weight: 700;
		font-style: italic;
		color: $color-dark-purple;
	}

	.profile-menu {
		min-width: 264px;
		box-shadow: 0px 12px 20px rgba(0, 45, 82, 0.5);
		top: 82px;
		right: -60px;
		left: auto;
		border-radius: 8px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: none;
		padding: 40px 25px;
		
		a {
			font-size: 14px;
			font-weight: 400;
			width: 100%;
			color: $color-dark-purple;
			line-height: 23px;

			&:empty {
				display: none;
			}
		}

	}

	.profile-links {
		@extend %profile-links;

		>a {
			&:last-child {
				border-bottom: 1px solid $color-primary-beige-medium;
				padding-bottom: 20px;
			}
		}

		&:after {
			@extend .clearfix;
		}
	}

	.profile-actions {
		a {
			color: $color-alto-dark;

			&.blue {
				color: $color-dark-purple  !important;
				text-decoration: underline;
			}
		}

		.pipeline {
			padding-right: 10px;
			padding-left: 10px;

			&::after {
				content: "\007C";
			}
		}
	}

	.profile-menu-accordion {
		&.active {
			&:after {
				@extend %arrow-up-fa;
			}
		}

		&:after {
			@extend %arrow-down-fa;
		}

		&:hover,
		&:focus {
			outline: none;
			text-decoration: none;
		}
	}

	.profile-menu-actions {
		background-color: transparent;
		margin-left: -25px;
		margin-right: -25px;
		display: none;
		overflow: hidden;
		padding-bottom: 0;

		li {
			margin: 0;
			position: relative;

			&:last-of-type {
				a {
					border-bottom: 0;
				}
			}
		}

		a {
			display: block;
			clear: both;
			padding: 10px 0px;
			border-bottom: 1px solid #e7e4df;
			width: 80% !important;
			margin: 0 auto;
		}

		.fa.fa-circle {
			right: 25px;
			@extend %fa-circle;
		}
	}

	.state-img {
		width: 58px;
		margin-right: 10px;
		border-radius: 4px;
	}

	//Mobile Layers
	.overlay-mobile {
		min-height: calc(100% - 64px);
		width: 100%;
		position: fixed;
		z-index: 50;
		left: 0;
		background-color: $color-white;
		overflow-y: auto;
		transition: .5s ease;
		opacity: 0;
		z-index: -1000;
		.overlay-content {
			position: relative;
			width: 100%;
			min-height: 100%;
			height: auto;

			>ul {
				list-style: none;
				margin: 0;

				// first level menu links
				>li {
					margin: 0;

					&:nth-last-child(2) {
						>a:last-child {
							border-bottom: none;
						}
					}

					>a {

						&:focus,
						&:hover {
							outline: none;
						}
					}
				}

				// first level menu
				a {
					margin: 0px 24px;
					padding: 24px 0;
					text-decoration: none;
					font-size: 18px;
					color: $color-dark-purple;
					display: block;
					transition: 0.3s;
					line-height: 25px;
					font-weight: 700;
					border-bottom: 1px solid #e7e4df;

					&.n-b-b {
						border-bottom: 0;
					}

					&.dropdown-toggle {
						&:after {
							@extend %arrow-down-blue;
							width: 15px;
							height: 8px;
							position: relative;
							float: right;
							top: 8px;
						}
					}
				}

				.open {
					>a.dropdown-toggle {
						border-bottom: 1px solid transparent;

						&:after {
							@extend %arrow-up-blue;
						}
					}
				}
			}

			// second level menu - position
			.open ul.dropdown-menu {
				position: static;
				float: none;
				width: auto;
				border: none;
				padding: 0;
				margin: 0;
				box-shadow: none;
			}

			// second level menu
			ul.dropdown-menu {
				background-color: $color-primary-beige;
				
				>li {
					margin: 0 24px;
					margin-right: 20px;

					@include respond-to("md") {
						display: list-item;
						height: auto;
					}

					>a {
						color: $color-dark-purple;
						font-size: 16px;
						line-height: 52px;
						padding: 0;
						margin: 0;
						font-weight: 400;

						&:focus {
							outline: none;
						}
					}
				}
			}

			// third level menu
			.dropdown-submenu {
				position: relative;

				>a {
					text-transform: capitalize;
					display: block;

					&:after {
						@extend %arrow-down-fa;
						margin-right: 12px;
					}

					&.open {
						&:after {
							@extend %arrow-up-fa;
						}

						border-bottom: 1px solid transparent;
					}
				}

				.dropdown-menu {
					a {
						font-size: 14px;
						padding: 0 16px;
						border-bottom: 1px solid #f4f3ef;

						&:hover,
						&:focus {
							background-color: inherit;
						}
					}
				}

				.open~ul.dropdown-menu {
					background-color: $color-white;
					margin-right: -24px;
					margin-left: -24px;
					border-radius: 0px;
				}
			}
		}

		#profile-menu {
			background-color: $color-primary-beige;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.card-container{
				flex-wrap: wrap;
				margin-left: 20px;
				@include respond-to("md") {
					flex-wrap: nowrap;
				}
				@include respond-to("xs-down") {
					flex-wrap: nowrap;
				}
			}
			.mobile-profile-menu__hot{
				padding: 0;
				.navbar-collapse{
					max-width: 100%;
					box-sizing: border-box;
				}
				.navbar{
					.navbar-nav{
						padding: 0;
						margin: 0;
						.dropdown-menu{
							border: none;
							border-radius: 0;
							padding: 0;
							margin: 0;
							.dropdown-item{
								// padding: 15px 20px;
								display: flex!important;
								height: 60px;
								display: flex;
								align-items: center;
								&:active{
									background-color: transparent;
								}
							}
							.badge-notification{
								width: 19px;
								height: 18px;
								background: #da5666;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-pack: center;
								-ms-flex-pack: center;
								justify-content: center;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								color: white;
								z-index: -1;
								border-radius: 3px;
								z-index: 1;
								margin-left: 5px;
							}
							.important-messages{
								display: flex!important;
								justify-content: start;
								align-items: center;
								position: relative;
								&:after{
									position: absolute;
									top: calc((60px / 2) - 4px);
									right: 20px;
								}

							}
							.mobile-menu-imortant-messages{
								background: white;
								padding: 15px 20px;
							}
						}
						li{
							margin: 0px;
							.dropdown-menu{
								li{
									margin: 0px 20px;
								}
							}
							
						}
					}
					.dropdown-toggle{
						padding: 15px 20px;
						height: 60px;
						// display: flex!important;
						align-items: center;
						justify-content: space-between;
						&:after{
							display: inline-block;
    						content: " ";
    						background: url(../images/new-icons/arrow-down-purple.svg) no-repeat;
							height: 8px;
							width: 12px;
							border: none;
							vertical-align: middle;
						}
						&.show{
							&:after{
								display: inline-block;
								content: " ";
								background: url(../images/new-icons/arrow-up-purple.svg) no-repeat;
								height: 8px;
								width: 12px;
								border: none;
							}
						}
						&#navbarDarkDropdownMenuLink{
							font-weight: bold;
							border-bottom: 1px solid #f4f3ef;
						}
					}
					.dropdown-item.show{
						background-color: #e9ecef;

					}
					.dropdown-menu{
						li{
							a{
								height: 100%;
								
							}
						}
					}
					.mobile-menu-imortant-messages{
						li{
							height: auto;
							a{
								height: auto;
							}
						}
					}
					.message-list{
						min-width: 100%;
						width: 100%;
						.message-title{
							color: $color-purple;
							font-weight: bold;
							font-size: 14px;
							line-height: 1.5;
							display: flex;
							align-items: baseline;
							justify-content: space-between;
							.important-message__see-less{
								display: none;
								border: none;
								background: transparent;
							}
						}
						&.message-expanded{
							.message-description{
								p{
									white-space: unset;
									font-size: 14px;
									font-weight: 600;
								}
							}
						}
						.message-description{
							display: flex;
							align-items: baseline;
							p{
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-size: 14px;
								// max-width: calc(100vw - 15%);
							}
							.important-message__see-more{
								color: $color-purple;
								font-size: 11px;
								padding-left: 5px;
								text-decoration: underline;
								cursor: pointer;
								display: none;
							}
						}
						
					}
				}
				
				.mobile-profile-menu{
					padding: 15px 24px;
					background: #f5f4f1;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.user-name-image{
						display: flex;
						align-items: center;
						.user-name{
							font-weight: bold;

						}
					}
					a{
						color: $color-purple;
						text-decoration: underline;
					}
					.pipeline{
						padding-right: 10px;
						padding-left: 10px;
						color: #37424A;
						&::after{
							content: "\007C";
						}
					}
				}
			}
			.profile-header {
				height: 72px;
				display: flex;
				align-items: center;
				margin: 0 24px;

				.profile-name {
					img {
						@extend .state-img;
					}

					span.name {
						font-weight: 600;
						font-size: 16px;
						color: $color-alto-dark;
					}
				}

				.profile-actions {
					justify-content: flex-end;
					display: flex;
					flex: 1;

					a {
						font-size: 12px;
						color: $color-alto-dark;
						font-weight: 400;
						padding-right: 6px;
					}

					.pipeline {
						font-size: 12px;
						padding-right: 12px;
					}
				}
			}

			.profile-links {
				background-color: $color-white;
				padding: 24px;

				>a {
					font-size: 14px;
					border-bottom: 1px solid #f4f3ef;
					line-height: 50px;
					padding-top: 0;
					padding-bottom: 0;

					&:last-child {
						border-bottom: none;
					}
				}
			}

			.profile-menu-actions {
				a {
					// padding: 10px 25px;
					font-size: 14px;
					width: 100% !important;
					position: relative;
				}

				.fa.fa-circle {
					right: 25px;
					@extend %fa-circle;
				}
			}
		}

		.get-started {
			background-color: $color-white;
			padding: 40px 24px;

			.actions {
				.btn {
					background-color: $color-dark-purple;
					padding: 10px 30px;
					font-size: 16px;
					color: $color-white;
					font-weight: 600;

					&:hover,
					&:focus {
						background-color: $color-cta-primary-roll;
					}
				}

				.link {
					display: block;
					padding-top: 20px;
					margin-bottom: 20px;
				}
			}
		}

		.card-container {
			margin: 24px;
			margin-bottom: 5px;
		}

		.dropdown-backdrop {
			display: none;
		}
	}
	.mp{
		margin-left: 40px;
	}

}

html.disableScrollSafari,
body.disableScrollSafari {
	overflow-y: hidden !important;
	position: fixed !important;
	height: 100% !important;
}

.subnav-headline {
	.slick-disabled {
		display: none !important;
	}
	.subnav-links-container{
		padding: 0px;
	}
	display: grid;
	grid-template-columns: 8% 84% 8%;
	background-color: $color-gray;
	height: 56px;
	@include respond-to("sm") {
		grid-template-columns: 10% 80% 10%;
	}
	@include respond-to("md") {
		display: flex;
		height: 84px;
	}
	
	.subnav-links-container {
		@include respond-to("md") {
			display: flex;
			align-items: center;
			max-width: 1200px;
			width: 95%;
    		margin: 0 auto;
		}

		@include respond-to("lg") {
			max-width: 1280px;
		}
		.menu-slider {
			padding-left: 0.75rem;

			@include respond-to("md") {
				display: flex;
			}

			.slick-slide {
				align-items: center;
    			justify-content: center;
			}
			
			a {
				display: flex !important;
				height: 56px;
				align-items: center;
				justify-content: center;
				font-size: 14.5px;

				&:hover,
				&:focus {
					color: $color-dark-purple;
				}

				@include respond-to("md") {
				 	height: unset;
					padding-left: 15px;
					padding-right: 15px;

					&:first-of-type {
						padding-left: 0;
					}
				} 	
				
				@include respond-to("lg") {
					font-size: 16px;
					padding-left: 15px;
					padding-right: 15px;
				}
		
				&.active {
					font-weight: bold;
				}

				&.right-cta {	
					height: 40px;
					@include respond-to("md") {
						display: none !important;
				   }
				}
			}
		}
		a.right-cta {
			background-color: white;
			font-weight: bold;
			margin-left: auto;
			padding: 10px 25px;
			border-radius: 4px;
			
			@include respond-to("md-down") {
				display: none;
		   	}
		}
	}

	.subnav-slider-navigation {	
		.back-arrow,
		.next-arrow {
			cursor: pointer;
			font-size: 34px;
			width: 100%;
			display: inline-block;
    		text-align: center;
			padding: 0;
			height: 80%;
			color: #6e6e6e;

			span {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.back-arrow {
			border-right: 1px solid $color-text-gray;
		}

		.next-arrow {
			border-left: 1px solid $color-text-gray;
		}
	}
	
}
.language-switch{
	background: $color-gray;
	border:none;
	&.active{
		background: $color-yellow;
	}
	&:hover{
		border: none;
		box-shadow: 0px 0px 1px 1px grey;
	}
	.navbar-text{
		color: $color-purple;
		font-size: 14px;
		padding: 2px 5px;
		text-align: center;
	}
}