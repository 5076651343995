.modal-header,
.modal-footer {
    border: none;
}

.modal-footer > div {
    margin: 0px;
    padding: 0px 15px;
}

.modal-header{
    justify-content: end;
    padding: 15px;
}

.modal-backdrop.in {
    filter: alpha(opacity=70);
    opacity: 0.7;
}

.close {
    opacity: 1;
    font-size: 30px;
    border: none;
    background: transparent;
    line-height: 1;
    margin-top: -2px;
    padding: 0px;
}

.modalForms {
    &.no-footer {
        .modal-body {
            padding-bottom: 80px;
        }
    } //no-footer
    .modal-body {
        text-align: center;
        padding-top: 0;
        @include respond-to("md") {
            padding-left: 100px;
            padding-right: 100px;
        }
        p {
            font-size: 14px;
            padding: 30px 0 50px 0;
            text-align: center;
            @include respond-to("md") {
                font-size: 21px;
            }
        }
        .btn {
            font-size: 14px;
            width: 100%;
            @include respond-to("md") {
                font-size: 16px;
            }
        }
        div[class^="col"]{
            padding-right: 15px;
            padding-left: 15px;
        }
    } //modal-body
    .modal-footer {
        text-align: center;
        padding-bottom: 60px;
        display: flex;
        @include respond-to("md") {
            padding: 40px 60px 50px 60px;
            text-align: left;
        }
        @include respond-to("md-down") {
            flex-direction: column;
        }
        p {
            padding-top: 10px;
            font-size: 14px;
            @include respond-to("md") {
                font-size: 16px;
            }
        } //p
        .share {
            a {
                color: rgba(102, 102, 102, 0.7) !important;
                width: 32px;
                text-align: center;
                padding: 0 13px;
                font-size: 25px;
                vertical-align: bottom;
                @extend %transition;
                &:hover {
                    color: rgba(102, 102, 102, 1) !important;
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
            @include respond-to("md") {
                float: right;
            }
        }
    } //modal-footer
} //registrationModal

.modal-home {
    .modal-dialog {
        height: 100vh !important;
        display: flex;
    }

    .modal-content {
        margin: auto !important;
        height: fit-content !important;
    }

    .modal-header {
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
    }

    .modal-body {
        padding-left: 40px;
        padding-right: 40px;

        p {
            line-height: 1.5 !important;
        }
    }
}

.modal-marketplace{
    .modal-dialog{
        .modal-header{
            justify-content: center;
            h2{
                width: 100%;
                font-weight: 700;
            }
        }
        .modal-body{
            button{
                width: 100%;
                height: 57.8px;
            }
        }
        .close{
            position: absolute;
            top: 10px;
            right: 15px;
        }
    }

}

.sessionModal{
    .modal-body{
        padding: 0px;
    }
}