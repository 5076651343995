/* FONT SETTINGS */
$font-base: 16px;
$font-family: 'Open Sans',
sans-serif;
$floating-label-font-size: 17px;
$floating-label-font-size-small: 14px;
$btn-new-width: 264px;

/* COLORS */
$color-dark-purple: #393E59;
$color-light-blue: #005DAA;
$color-cta-primary-roll: #6b4099;
$color-orange: #f26835;
$color-cta-tertiary-roll: #9C4700;
$color-yellow: #fec835;
$color-gray: #F5F4F1;
$color-gray-dark: #e8e9ec;
$color-text-dark: #08202d;
$color-text-blue:#1c3a53;
$color-text-gray:#81939F;
$color-aquamarine:#0099b2;
$color-turquoise:#0099b2;
$color-gray-light:#d8d8d8;
$color-red:#f95567;
$color-footer:#002c44;
$color-gray-plain:#ccc;
$color-purple:#4f2e84;
$color-dark-purple:#393e59;
$color-light-purple:#A43482;
$color-pink:#DF4F71;
$color-pink-hover:#b13c5b;
$color-green:#21a540;
$color-cta-secondary-roll: #004F44;
$color-black-alpha:rgba(0, 0, 0, 0.7); 
$color-white-alpha:rgba(255, 255, 255, 0.7);
$color-dark-gray: #333333;
$color-fuchsia: #a43382;

/* COLORS - NEW DESIGN */

$primary-color: $color-purple;
$secondary-color: #fec835;
$color-primary-beige: #F5F4F1;
$color-primary-beige-medium: #D9D5CB;
$color-primary-midnight: #0C2C42;
$color-alto-dark: #37424A;
$color-alto-medium: #5C6F7C;
$color-alto-light: #ADB8BF;
$color-beige-light-05: #fafaf8;
$color-new-red: #FF0000;
$color-white: #FFFFFF;
$color-black: #000000;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 480px,
//   md: 768px,
//   lg: 992px,
//   xl: 993px
// );

$container-max-widths: (
  md: 750px,
  lg: 970px,
  xl: 1170px,
);
