.search {
    .loader {
        position: absolute;
        top: 7px;
        right: 32px;
        z-index: 9;

        .sk-circle {
            margin: 0;
            width: 20px;
            height: 20px;
        }
    }

    .close-search {
        display: none;
        font-weight: 200;
    }

    //loader
    &#desktop-search {
        //.easy-autocomplete{
        position: relative;
        float: right;
        width: 100% !important;
        text-align: right;
        margin-right: 15px !important;
        z-index: 9;

        &.new-search {
            width: 530px !important;
            position: absolute;
            right: calc(100% + 20px);
            top: 50%;
            transform: translateY(-50%);
            margin-right: 0 !important;
            display: none;

            input {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: left;
                padding-left: 25px;
                font-size: 32px;
                font-style: italic;
                font-family: $font-family;
                font-weight: 200;
                box-shadow: 0px 12px 20px rgba(0, 45, 82, 0.5);
                -webkit-border-bottom-right-radius: 15px;
                -webkit-border-bottom-left-radius: 15px;
                -moz-border-radius-bottomright: 15px;
                -moz-border-radius-bottomleft: 15px;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-radius: 15px;

                &:focus,
                &:hover {
                    padding-left: 25px;
                }
            }

            i {
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 36px;
                color: $color-dark-purple;
                cursor: pointer;
                font-style: normal;

                &.close-search {
                    right: 65px;
                }
            }

            &.erase-show {
                i.fa-times.erase {
                    right: 67px;
                }

                .loader {
                    right: 105px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                input {
                    padding-right: 133px;
                }
            }

            &.box-opened {
                input {
                    border-radius: 0;
                    border-bottom: 1px solid $color-dark-purple;
                }

            }

            .suggesstion-box,
            .suggestion-box-mkp {
                @include respond-to('md') {
                    top: 51px;
                }
            }
        }

        //new-search

        input {
            width: 80%;
            position: relative;
            text-align: center;
            border-color: #fff;
            border-radius: 2px;
            border-style: solid;
            border-width: 1px;
            @extend %transition;
            box-shadow: none;
            text-align: right;
            padding-right: 55px;
            padding-top: 5px;
            padding-bottom: 5px;

            &:focus,
            &:hover {
                outline: none;
                width: 100%;
                text-align: left;
                padding: 5px 10px;
                border-color: #ccc;
                color: #555;
                float: none;
                @extend .box-shadow-complete;
            }
        }

        i {
            position: absolute;
            right: 0px;
            top: 0px;
            font-size: 16px;
            color: $color-black-alpha;
            width: 30px;
            height: 36px;
            //background: rgba(138, 59, 59, 0.35);
            text-align: center;
            line-height: 34px;

            &.fa-times {
                cursor: pointer;
                right: 25px;
                display: none;
            }
        }

        //}

        &.erase-show {
            input {
                padding-right: 78px; //50px;
            }

            .loader {
                right: 53px;
            }

            i.fa-times {
                right: 25px;
                display: block;
            }
        }
    }

    &#store-search {
        position: relative;
        width: 100% !important;
        font-size: 1.5rem;

        input {
            width: 100%;
            position: relative;
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            box-shadow: none;
            text-align: left;
            padding-top: 5px;
            border-color: #ccc;
            padding: 5px 25px;
            padding-left: 40px;
            line-height: 30px;
            height: 65px;
            font-size: 17px;

            &:focus,
            &:hover {
                border-color: #b7b7b7;
                color: #555;
                outline: none;
            }

            &::placeholder {
                color: #333333;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: #333333;
            }

            &::-ms-input-placeholder {
                color: #333333;
            }
        }

        i {
            position: absolute;
            left: 8px;
            top: 0px;
            font-size: 18px;
            color: $color-dark-purple;
            width: 30px;
            height: 40px;
            text-align: center;
            line-height: 65px;

            &.fa-times {
                cursor: pointer;
                display: none;
            }
        }

        &.erase-show {
            .loader {
                right: 30px;
                top: 20px;
            }

            i.fa-times {
                left: calc(100% - 30px);
                top: 0;
                display: block;
            }
        }
    }

    //li#search
    .suggesstion-box,
    .suggestion-box-mkp {
        max-height: 230px;
        width: 100%;
        overflow-y: auto;
        position: absolute;
        right: 0;
        z-index: 9;
        border: none;
        background: white;
        top: 60px;
        //display: none;
        @extend .box-shadow-complete;

        //for desktop
        @include respond-to("md") {
            top: 35px;
        }

        a {
            width: 100%;
            display: block;
            margin: 0;
            padding: 10px 15px !important;
            border: none;
            text-align: left;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                background: $color-gray;
            }
        }

    }

    .suggestion-box-mkp {
        top: 65px;
    }

    &#mobile-search {
        margin: 24px;
        position: relative;
        width: 100%;

        &:after,
        &:before {
            @extend %clear;
        }

        @include respond-to('lg') {
            display: none;
        }

        #search-mobile {
            width: 100%;
            height: 60px;
            font-size: 17px;
            border: none;
            box-shadow: none;
            text-align: left;
            padding-right: 35px;
            background: white;
            border: 1px solid #D9D5CB;
            box-sizing: border-box;
            border-radius: 3px;
            @extend %transition;
            font-family: $font-family;
            font-style: italic;


            &:hover,
            &:focus {
                &+i.fa-search {
                    display: block;

                }
            }
        }

        i {
            position: absolute;
            top: 0;
            right: 0;
            font-style: normal;
        }

        i.fa-search {
            display: block !important;
            color: $color-black-alpha;
            display: none;
            width: 32px;
            height: 61px;
            //background: #00000042;
            text-align: center;
            line-height: 61px;
        }

        i.fa-times {
            cursor: pointer;
            display: none;
            height: 61px;
            line-height: 60px;
            right: 30px;
            width: 30px;
            text-align: center;
        }

        .loader {
            top: 20px;
            right: 36px;
        }

        &.erase-show {
            .loader {
                top: 20px;
                right: 65px;
            }

            #search-mobile {
                padding-right: 90px;
            }
        }

        .suggesstion-box {
            a {
                font-weight: 500;
            }
        }
    }

    //mobile-search
}

//search
section.new-design {

    .search-icon {
        margin-left: 5px;

        &::after {
            display: inline-block;
            width: 20px;
            height: 20px;
            content: " ";
            vertical-align: text-top;
        }

        &.white {
            &::after {
                background: url(../images/new-icons/icon-search-new.svg) no-repeat;
            }
        }

        &.gray {
            &::after {
                background: url(../images/new-icons/icon-search-new-gray.svg) no-repeat;
            }
        }

        &.blue {
            &::after {
                width: 37px;
                height: 37px;
                background: url(../images/new-icons/icon-search-new-blue.svg) no-repeat;
            }
        }

        &.purple {
            &::after {
                width: 37px;
                height: 37px;
                background: url(../images/new-icons/icon-search-new-purple.svg) no-repeat;
            }
        }
    }

    #mobile-search {

        i {
            line-height: 60px;
            cursor: pointer;
        }

        &.erase-show .loader {
            right: 70px;
        }

        .close-search {
            right: 45px;
            font-size: 30px;
            color: $color-primary-beige-medium;
        }

        .search-icon {
            top: 23px;
            right: 15px;

            &:after {
                vertical-align: top;
            }
        }
    }

    .close-icon {
        margin-left: 5px;
        // vertical-align: middle;
        font-size: 35px;
        font-weight: 200;
        display: none;
        height: auto;
        line-height: 0;
    }
}

#searchButton.opened {
    i.search-icon.white {
        display: none;
    }

    span.close-icon {
        display: block;
    }
}

.cards-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include respond-to('md') {
        margin-top: 0;
    }
}

.search-bar {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 2;
    margin-bottom: 25px;
    transition:
			300ms ease background-color,
			500ms padding;
}

.search-bar.is-pinned {
    background-color: $color-gray;
    padding: 15px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 2px 0px rgba( 0, 0, 0, 0.1);
    transition:
        300ms ease background-color,
        500ms ease color,
        500ms padding;
}

.top-marker,
.bottom-marker {
    height: 0px;
    left: 0px;
    position: absolute;
    width: 0px;
}

.top-marker {
    top: -1px;
}

.bottom-marker {
    bottom: -1px;
}