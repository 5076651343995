$bg-colors: (
white:white, 
aquamarine: $color-aquamarine, 
gray: $color-gray, 
gray-dark: $color-gray-dark, 
red:$color-red, 
blue:$color-dark-purple, 
purple:$color-purple,
dark-purple: $color-dark-purple,
dark-blue:$color-footer, 
green:$color-green, 
turquoise: $color-turquoise,
gray-l:lighten($color-gray, 2.5), 
gray-light: $color-beige-light-05);
@each $name,
$color in $bg-colors {
    .bg-#{$name} {
        background: $color;
        padding: 10px 0;
    }
    #{'$name'} {
        color: $color;
    }
} //each