.col-lg-9.col-centered{
    padding: 0px 15px;
}
.panel-group.accordion{
    margin-bottom: 20px;
    .accordion-item{
        background: none;
        border:none;
        border-radius: 0;
        box-shadow: none;
        &+.panel {
            margin-top: 0;    
        }
        &:first-child(){
            .panel-heading{
                border-top: 1px solid lighten($color-gray-plain, 10%);
            }  
        }
        &:last-child(){
            .panel-heading{
                border-top: none;
            }  
        }
        &-default{
            > .panel-heading{
                background: none;
                border-radius: 5px;
                border-bottom: 1px solid lighten($color-gray-plain, 10%);
                padding:20px 10px;
                .accordion-header{
                    position: relative;
                    font-size: 16px;
                    button{
                        border: 0px;
                        background-color: inherit;
                        color: #000000;
                        cursor:pointer;
                        font-size: em(16);
                        line-height: 1.5;
                        text-align: left;
                        width: 100%;
                        display: block;
                        padding-right: 30px;
                        @extend %font-normal;
                       
                        &:after{
                            content: " ";
                            background: url(../images/new-icons/arrow-up-purple.svg) no-repeat;
                            color: $color-purple;
                            float: right;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 20px;
                            height: 20px;
                        }
                        &.collapsed{
                            &:after{
                                content: " ";
                                background: url(../images/new-icons/arrow-down-purple.svg) no-repeat;
                            }   
                        }
                        @include respond-to('md'){
                            font-size: 1.3125em;
                        }
                    }//span
                }//panel-title
            }//panel-heading
            .panel-collapse{
                &.in{
                    border-bottom: 1px solid lighten($color-gray-plain, 10%);
                    border-radius: 5px;
                }
            }
            .accordion-body{
                border:none;
                padding: 30px 15px;
                color:$color-black-alpha;
            }
        }//deafult
    }//panel
}//accordion

#faq{
    &.bg-white{
        .panel-group{
            .panel-default{

            }
        }
    }
    &.bg-gray{
        .panel-group{
            .panel-default{
                
            }
        }
    }
        
}