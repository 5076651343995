footer {
    background: $color-footer;
    .container-fluid{
        max-width: 1280px;
        padding: 0 15px;
        padding-top: 25px;
    }
    .container {
        padding-top: 25px;
        @include respond-to('sm') {
            padding-top: 50px;
            padding-bottom: 20px;
        }  
    }

    p.header {
        font-size: em(18);
        line-height: em(23);
        padding: 20px 0 7px 0;
        color: $secondary-color;
        font-weight: bold;
        a{
            font-size: 1.125em;
            line-height: 1.4375em;
            padding: 20px 0 7px 0;
            color: #fec835;
            font-weight: bold;
        }
    }

    .row {
        @include respond-to("md") {
            display: flex;
        }

        .col-md-3 {
            @include respond-to("md") {
                align-self: flex-end;
            }
        }
    }

    .footer-menu {
        display: flex;
        padding-bottom: 80px;

        &.row {
            padding: 0;
            margin: 0;
        }

        .divider {
            padding: 10px 0;
        }

        .col {
            max-width: (100% / 5);
            width: 100%;
            padding: 0 10px;
            flex: auto;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }

        .c-soon {
            color: rgba(255, 255, 255, 0.5);
        }

    }

    //footer
    a {
        display: block;
        width: 100%;
        padding: 5px 0;
        color: #cac0da;

        &:hover,
        &:focus{
            color: #e6e6e6;
            padding: 5px 0;
        }
    }

    .share {
        a {
            color: white;
            display: inline-block;
            margin-right: 10px;
            width: auto;

            &:last-child {
                margin-right: 0;
            }

            .svg-inline--fa {
                color: white;
                font-size: em(23);

                &:last-child {
                    margin-left: 0;
                }
            }
        }

        //a
    }

    //share
    .footer-logo {
        width: 149px;
        display: block;
        margin: 45px 0;

        @include respond-to ('md') {
            margin: 30px 0;
        }
    }

    .sub-menu {
        margin: 0 0 44px 0;
        padding: 0;
        list-style-type: none;

        @include respond-to('sm') {
            margin: 0 0 40px 0;
        }

        li {
            padding: 0 20px;
            margin-bottom: 15px;
            float: left;
            border-right: 1px solid white;

            &:nth-child(4n) {
                padding-left: 0;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border: none;
            }

            a {
                color: white;
                font-size: em(13);
                line-height: em(13);
                padding: 0;
            }

            @include respond-to('xxs') {
                padding: 0 15px;
            }

            @include respond-to('sm') {
                margin-bottom: 0;

                &:nth-child(3n+3) {
                    border-right: 1px solid white;
                }

                &:nth-child(3n+1) {
                    clear: initial;
                    padding: 0 20px;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border: none;
                }
            }
        }

        //li
        &:after {
            @extend .clearfix;
        }
    }

    //sub-menu
    .copy,
    .address {
        margin-top: 40px;
        font-size: em(12);
        line-height: em(25);
        color: rgba(255, 255, 255, 0.5);
    }

    .copy {
        @include respond-to ('md') {
            margin-top: 40px;
        }
    }

    .address {
        margin-top: 10px;
        font-size: em(13);
        line-height: em(22);

        p {
            a {
                padding: 0;
            }
        }
    }

    #accordion-footer {
        .panel-default {
            &.panel {
                background: none;
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            border:none;

            >.panel-heading {
                color: white;
                background: none;
                border: none;
                padding: 10px 0;
                // padding-bottom: 0;

                h4.panel-title {
                    margin-bottom: 0;
                    >a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // padding-bottom: 0;
                        font-size: em(18);
                        color:$secondary-color;
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            border: none;
                            outline: none;
                        }

                        .svg-inline--fa {
                            font-size: 13px;
                        }

                        >.fa-plus {
                            display: none;
                        }

                        >.fa-minus {
                            display: block;
                        }

                        &.collapsed {
                            >.fa-plus {
                                display: block;
                            }

                            >.fa-minus {
                                display: none;
                            }
                        }
                    }

                    //a
                }

                //panel-title
                +.panel-collapse>.panel-body {
                    border: none;
                    padding: 0;
                    padding-bottom: 0px;

                    a {
                        font-size: em(16);
                    }
                }

                //panel-body
            }

            //panel-heading
        }

        //panel-default
    }

    //accordion-footer
    .new-design{
        padding: 0 2.5%;
    }
    .new-design, div.new-design~section {
        background:$primary-color;

        @include respond-to('lg') {
            .container {
                width: 1280px;
            }
        }

        div.col {
            a {
                line-height: 2;
            }
        }

        .col-left {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;

            p {
                width: 90%;
                color: #A6AEBA;
            }

            @include respond-to('sm') {
                width: 80%;
            }

        }

        .col-right {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;

            img {
                width: 155px;
            }

            @include respond-to('sm') {
                width: 20%;
                padding-right: 0;
                padding-left: 0;
            }
        }

        .address {  
            a, p {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.5);
                line-height: 20px;
            }
        }

        .footer-logo {
            width: 156px;
            margin-bottom: 10px;

            @include respond-to('sm') {
                width: 207px;
                margin-bottom: 15px;
            }
        }

        .sub-menu {
            @include respond-to('sm') {
                margin: 0 0 20px 0;
            }

            li {
                padding: 0 14px;

                &:first-child {
                    padding-left: 0;
                }

                &:nth-child(4n) {
                    padding-left: 0;
                }

                @include respond-to('sm') {
                    padding: 0 20px;

                    &:nth-child(4n) {
                        padding-left: 20px;
                    }
                }

                a {
                    color: white;
                    font-size: 14px;

                    @include respond-to('sm') {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }


        }

        .copy {
            font-size: 14px;
            line-height: 18px;
            color: #A6AEBA;

            @include respond-to ('md') {
                margin-top: 30px;
            }
        }

        .card-title {
            color: $color-alto-light;
            font-size: 14px;
            line-height: 1.3;
            font-weight: bold;
        }

        a.arrow-link {
            font-size: 16px;
            line-height: 24px;
            color: $secondary-color;
        }

        .share {
            a {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .alert-text {
            font-weight: 700;
            color: $color-white;
        }

        #accordion-footer {
            .accordion-collapse{
                transition: 0.3s;
            }
            .panel-heading {
                .panel-title {
                    font-size: 14px;
                }
            }

            .panel-body {
                font-size: 14px;
            }
        }
    }
}

//footer